import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const { ENV_X_API_KEY } = import.meta.env;

export interface SonoArtisteData {
  id?: number;
  email: string;
  name?: string;
  surname?: string;
  paypal_email?: string;
  country?: string;
  language?: string;
  city?: string;
  street?: string;
  postal_code?: string;
  company?: boolean;
  comany_name?: string;
  vat_number?: number;
  country_phone_number?: number;
  contact_phone?: number;
  is_on_sono: boolean;
  date_created?: string;
}

interface ArtisteState {
  data: SonoArtisteData;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ArtisteState = {
  data: {
    email: "",
    is_on_sono: true,
  },
  status: "idle",
  error: null,
};

export const fetchSonoArtisteData = createAsyncThunk<
  SonoArtisteData,
  void,
  { rejectValue: string }
>("sono/isUserExist", async (_, { rejectWithValue }) => {
  try {
    // const email = "bigtrilkaiza@gmail.com";
    const email = localStorage.getItem("email");
    const response = await axios.get<SonoArtisteData>(
      `${import.meta.env.ENV_CONSOLE_ENDPOINT}/artists/api/artist?email=${email}`,
      {
        headers: {
          "X-Api-Key": ENV_X_API_KEY,
          "Content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue("Failed to fetch artiste data");
  }
});

const sonoArtisteSlice = createSlice({
  name: "sonoArtist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSonoArtisteData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchSonoArtisteData.fulfilled,
        (state, action: PayloadAction<SonoArtisteData>) => {
          state.status = "succeeded";
          state.data = {
            ...action.payload,
            is_on_sono: action.payload?.email?.length > 0 ? true : false,
          };
        }
      )
      .addCase(fetchSonoArtisteData.rejected, (state, action) => {
        state.status = "failed";
        state.data = {
          email: "",
          is_on_sono: false,
        };
        state.error = action.payload ?? "Unknown error occurred";
      });
  },
});

export default sonoArtisteSlice.reducer;
