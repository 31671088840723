import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const { ENV_X_API_KEY } = import.meta.env;

interface CountryInterface {
  label: string;
  value: string;
}

interface SonaFormData {
  email: string;
  phone: string;
  name: string;
  country: CountryInterface | any;
  city?: string;
  street?: string;
  postal_code?: string;
  paypal_email?: string;
  company_name?: string;
  vat_number?: string;
  country_phone_number?: string;
}

export const sonoRegistrationAction = createAsyncThunk(
  "sonoRegistration",
  async (payload: SonaFormData, navigate) => {
    try {
      const response = await axios.post(
        `${import.meta.env.ENV_CONSOLE_ENDPOINT}/artists/api/create/`,
        { ...payload, country: payload.country.value },
        {
          headers: {
            "X-Api-Key": ENV_X_API_KEY,
            "Content-type": "application/json",
          },
        }
      );
      // @ts-ignore
      navigate("/dashboard");
      return response.data.status;
    } catch (error: any) {
      console.log(error);
      return error.message;
    }
  }
);

const sonoRegistationSlice = createSlice({
  name: "sonoRegistration",
  initialState: {
    loading: false,
    error: null,
    response: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sonoRegistrationAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sonoRegistrationAction.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action?.payload;
      })
      .addCase(sonoRegistrationAction.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default sonoRegistationSlice.reducer;
