import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const { ENV_X_API_KEY } = import.meta.env;

interface ArtisteData {
  artiste: {
    email: string;
  };
  net_total_artiste: number;
  date_updated: string;
}

interface ArtisteState {
  data: ArtisteData | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ArtisteState = {
  data: null,
  status: "idle",
  error: null,
};

export const fetchArtisteData = createAsyncThunk<
  ArtisteData,
  void,
  { rejectValue: string }
>("artiste/fetchArtisteData", async (_, { rejectWithValue }) => {
  try {
    // const email = "bigtrilkaiza@gmail.com";
    const email = localStorage.getItem("email");
    const response = await axios.get<ArtisteData>(
      `${import.meta.env.ENV_CONSOLE_ENDPOINT}/artists/api/royalties/artist?email=${email}`,
      {
        headers: {
          "X-Api-Key": ENV_X_API_KEY,
          "Content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue("Failed to fetch artiste data");
  }
});

const artisteSlice = createSlice({
  name: "artiste",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchArtisteData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchArtisteData.fulfilled,
        (state, action: PayloadAction<ArtisteData>) => {
          state.status = "succeeded";
          state.data = action.payload;
        }
      )
      .addCase(fetchArtisteData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload ?? "Unknown error occurred";
      });
  },
});

export default artisteSlice.reducer;
